<template>
  <div class="train-manage">
    <div class="header">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>训练管理</el-breadcrumb-item>
        <el-breadcrumb-item>训练信息</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="train-txt">
      <div class="train-title"><span>训练信息</span></div>
      <div class="backto">
        <el-button type="primary" @click="backTo">返回</el-button>
      </div>
    </div>

    <div class="train-container">
      <el-table
        :data="tableData"
        style="width: 100%"
        :cell-style="{ color: 'rgba(52, 52, 65, 1)', borderRight: 'unset' }"
        :header-cell-style="{
          color: 'rgba(19, 19, 27, 1)',
          background: '#F3F4FA',
          borderRight: 'unset',
        }"
        border
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-form label-position="left" inline class="demo-table-expand">
              <el-form-item class="left-one">
                <div v-for="(items, index) in props.row.children" :key="index">
                  <span
                    >{{ items.process_index }}/{{
                      items.process_page_total
                    }}训练</span
                  >
                </div>
              </el-form-item>
              <el-form-item class="left-two">
                <div
                  v-for="(tItems, tindex) in props.row.children"
                  :key="tindex"
                >
                  <span>{{ tItems.process_rate }}%</span>
                </div>
              </el-form-item>
              <el-form-item class="left-three">
                <div
                  v-for="(tItems, tindex) in props.row.children"
                  :key="tindex"
                >
                  <el-button
                    @click="handleClick(props.row, tItems)"
                    type="text"
                    size="small"
                    >查看答案</el-button
                  >
                </div>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column label="理论题库分类" prop="category_name">
        </el-table-column>
        <el-table-column label="训练名称" prop="train_name" align="center">
        </el-table-column>
        <el-table-column label="训练次数" prop="times_key" align="center">
        </el-table-column>
        <el-table-column label="正确率" prop="true_rate" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.true_rate }}%</span>
          </template>
        </el-table-column>
        <el-table-column label="开始时间" prop="start_time" align="center">
        </el-table-column>
        <el-table-column label="结束时间" prop="end_time" align="center">
        </el-table-column>
        <el-table-column label="操作" prop="desc" align="center">
        </el-table-column>
      </el-table>
      <el-pagination
        class="pages-center"
        :current-page="listPages.currentPageNum"
        :page-size="listPages.eachPageNum"
        layout="prev, pager, next, jumper"
        :total="listPages.total"
        @current-change="pageCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { showAnswerStudentList } from "@/utils/apis";
export default {
  data() {
    return {
      tableData: [],
      listPages: {
        currentPageNum: 1,
        eachPageNum: 10,
        total: 0,
      },
    };
  },
  mounted() {
    this.showListData();
  },
  methods: {
    showListData() {
      let params = {
        paging: "1",
        pageSize: this.listPages.eachPageNum,
        page: this.listPages.currentPageNum,
      };
      showAnswerStudentList(params).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data.list;
          console.log(res.data.list);
          this.listPages.total = res.data.total;
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
      });
    },
    // 切换分页
    pageCurrentChange(val) {
      this.listPages.currentPageNum = val;
      this.showListData();
    },
    backTo() {
      this.$router.push({
        path: "/student/train/index",
      });
    },
    handleClick(row, tItems) {
      this.$router.push({
        path: "/student/train/studentAnswerDetail",
        name: "StudentAnswerDetail",
        query: {
          train_id: row.train_id,
          times_id: row.times_id,
          process_index: tItems.process_index,
          process_page_total: tItems.process_page_total,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.train-manage {
  min-height: calc(100vh - 120px);
  background: #f7f9fb;
  .header {
    span {
      font-size: 14px;
    }
  }
  .train-txt {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #333333;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-size: 20px;
    font-weight: 500;
    line-height: 1;
    margin: 20px 0;
    ::v-deep .el-button--primary {
      border-color: rgba(17, 34, 216, 1);
      background: rgba(17, 34, 216, 1);
    }
    .reset-train {
      border: 1px solid #fd4446;
      padding: 10px 11px;
      color: #fd4446;
      &:hover {
        color: #fd4446d9;
        background-color: #fff;
        border: 1px solid #fd4446d9;
      }
    }
  }
  .train-container {
    background: #fff;
    padding: 20px;
    min-height: calc(100vh - 210px);
    /*height: calc(100vh - 189px);*/
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    .left-one {
      position: relative;
      left: 22.5%;
    }
    .left-two {
      position: relative;
      left: 47%;
    }
    .left-three {
      position: relative;
      left: 85%;
    }
    .pages-center {
      margin-top: 15px;
      text-align: right;
    }
  }
  .train-dialog-content {
    display: flex;
    flex-direction: column;
    line-height: 2;
  }
}
</style>